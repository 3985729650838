import React, { Component } from "react";
import { API_BASE_URL } from '../config';
import {
	Grid,
	TextField,
	Paper,
	Button,
	CircularProgress,
} from '@material-ui/core';
import { MIN_PASSWORD_LENGTH } from "../config";

const centerPaperOnPageStyle = {
	width: '50%',
	margin: '0 auto',
	padding: '10px'
};
const inputWidth = {
	width: '90%'
}

class ResetPassword extends Component {

    constructor(props) {
        super(props);
        const queryString = require('query-string');
        let qs = window.location.hash.replace("/resetpassword?", ""); // because reasons... or.. the people working on React are idiots? maybe.
        let parsed = queryString.parse(qs);

        let qEmail = parsed.email;
        let qToken = parsed.token;
        // Don't call this.setState() here!
        this.state = {
            isBusy: false,
            hasSubmitted: false,
            message: '',
            token: qToken,
            email: qEmail,
            password: '',
            confirmPassword: '',
        };

    }

    submit = () => {
        this.setState({
            ...this.state,
            isBusy: true,
        });
        // const params = queryString.parse(this.props.location.search);
        // values.email = params.email;
        // values.token = params.token;
        let values = {
            email: this.state.email,
            token: this.state.token,
            newPassword: this.state.password,
            confirmPassword: this.state.confirmPassword,
        }
        return fetch(`${API_BASE_URL}/account/resetPassword`, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(values),
        })
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        ...this.state,
                        isBusy: false,
                        hasSubmitted: true,
                    });
                }
                else if (response.status === 400) {
                    this.setState({
                        ...this.state,
                        isBusy: false,
                        hasSubmitted: false,
                    });
                    return response.json()
                    .then((data) => {
                        if (!!data.modelState['']) { // Invalid token
                            alert('This page is no longer valid. You can try to restart your password reset process.');
                            return;
                        }
                        let data2 = {};
                        for (let key in data.modelState) {
                            // model.Email -> email
                            let newKey = key.substr(6);
                            newKey = newKey[0].toLowerCase() + newKey.substr(1);
                            data2[newKey] = data.modelState[key][0];
                        }
                        // throw new SubmissionError({
                        //     ...data2,
                        // });
                    });
                }
                else {
                    this.setState({
                        ...this.state,
                        isBusy: false,
                        hasSubmitted: false,
                    });
                    console.log(this.state);
                }
            });
    };

    render() {
        // it should not be possible to land on this page unless following a link from and email generated with /preResetPassword
        // if (!(params && params.email && params.token)) {
        //     return <Redirect to="/preresetpassword"></Redirect>
        // }

        return (
            this.state.hasSubmitted ?
                <div>
                    <Paper style={centerPaperOnPageStyle}>
                        <p>
                            Successfully reset password. Your new password is effective immediately.
                        </p>
                    </Paper>
                </div>
                :
                <div>
                    <Paper style={centerPaperOnPageStyle}>
                        <Grid
                        spacing={2}
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                        >
                        <Grid item xs={12}>
                            <p>

                            </p>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                            style={inputWidth}
                                type="password"
                                label='New password'
                                error={this.state.password < MIN_PASSWORD_LENGTH}
                                helperText={`Password must be at least ${MIN_PASSWORD_LENGTH} characters long`}
                                value={this.state.password}
                                onChange={(evt) => this.setState({ password: evt.target.value })}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                            style={inputWidth}
                            type="password"
                                label='Confirm password'
                                error={this.state.confirmPassword !== this.state.password}
                                helperText='Passwords must match'
                                value={this.state.confirmPassword}
                                onChange={(evt) => this.setState({ confirmPassword: evt.target.value })}
                            />
                        </Grid>
                        </Grid>

                        <Button disabled={this.state.isBusy} color="primary" variant="contained" onClick={() => this.submit()}>
                            {this.state.isBusy && (
                                <CircularProgress size={20} />
                            )}
                            Next
                        </Button>
                    </Paper>
                </div>
        );
    }
}

export default ResetPassword;