import React from 'react';
import { createTheme } from '@material-ui/core/styles'
import { fetchUtils, Admin, Resource } from 'react-admin';
import { Route } from 'react-router-dom';
import RegistrationForm from './components/Registration.v2.jsx';
import dataProvider from './dataProvider.jsx';
import authProvider from './authProvider.jsx';
import './App.css';
import { API_BASE_URL } from './config.jsx';
import PreResetPassword from './components/PreResetPassword.jsx';
import ResetPassword from './components/ResetPassword.jsx';
import PrivacyPolicy from './components/PrivacyPolicy.jsx';
import TermsConditions from './components/TermsandConditions.jsx';

export const DanfossRed = { main: '#EE1B22'};
const theme = createTheme({
  palette: {
    type: 'light',
    secondary: DanfossRed
  },
});
const httpClient = (url, options = {}) => {
  if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json'});
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
}

export {httpClient};

const App = () => (
  // <JssProvider generateClassName={generateClassName}>
      <Admin title="HEXSelector Administration"
          // dashboard={Statistics}
          theme={theme}
          customRoutes={[
              <Route exact path="/registration" render={() => <RegistrationForm />} noLayout />,
              <Route exact path="/preresetpassword" render={() => <PreResetPassword />} noLayout />,
              <Route exact path="/resetpassword" render={() => <ResetPassword />} noLayout />,
              <Route exact path="/privacypolicy" render={() => <PrivacyPolicy />} noLayout />,
              <Route exact path="/termsconditions" render={() => <TermsConditions />} noLayout />,
          ]}
          dataProvider={dataProvider(API_BASE_URL, httpClient)}
          authProvider={authProvider}
          >
            <Resource name="#" />
            <Resource name="registration" />
            <Resource name="preresetpassword" />
            <Resource name="resetpassword" />
            <Resource name="privacypolicy" />
            <Resource name="termsconditions" />
      </Admin>
  // </JssProvider>
);
export default App;
