import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS  } from 'react-admin';
import { TOKEN_BASE_URL } from './config';
import  secureLocalStorage  from  "react-secure-storage";

// dummy values to make it prettier and easier to query logged in user access
// just add more when the need arises and update the isInRole method and Permissions object to handle the new role
export const ROLE_ADMIN = "1";
export const ROLE_LLM = "2";
export const ROLE_USER = "3";
export const ROLE_API = "4";
export const ROLE_UACTIVITY = "5";

export const isInRole = (role, permissions) => {
    if (!permissions || !role) {
        return false;
    }
    switch (role) {
        case ROLE_ADMIN: return permissions.isAdmin;
        case ROLE_LLM: return permissions.isLLM;
        case ROLE_USER: return permissions.isUser;
        case ROLE_API: return permissions.isApiUser;
        case ROLE_UACTIVITY: return permissions.isUaUser;
        default: return false;
    }
}


/* we don't export these methods below since we don't want whatever component needs the info to have to deal with roles and all that stuff raw
 * we just return a completed Permissions object once the AuthProvider resolves the AUTH_GET_PERMISSIONS call.
 * that way we can offer a simple flow for permissions check:
 * 
 * import { ROLE_ADMIN, ROLE_LLM, isInRole } from './authProvider';
 * 
 * const { permissions } = usePermissions();
 * const isAdmin = isInRole(ROLE_ADMIN, permissions);
 * const isLLM = isInRole(ROLE_LLM, permissions);
 * 
 * That's it. permissions will be null until ap resolves but isInRole accounts for that and will just return false until the async usePermissions() call completes.
 * Once the call resolves the component re-mounts and everything should work as expected
*/
const isLLM = (roles) => {
    return roles.indexOf('hexselector_llm') >= 0;
}

const isAdmin = (roles) => {
    return roles.indexOf("admin") >= 0 || roles.indexOf("admin_phecalc") >= 0 || roles.indexOf("admin_hexselector") >=0;
}

const isUser = (roles) => {
    return roles.indexOf('app_hexselector') >= 0;
}

const isApiUser = (roles) => {
    return roles.indexOf('hexselector_api') >= 0;
}
const isUaUser = (roles) => {
    return roles.indexOf('hexselector_uactivity') >= 0;
}

const Permissions = (roles) => {
    let admin = false;
    let llm = false;
    let user = false;
    let api = false;
    let uac = false;
    try {
        if (roles !== undefined && roles !== null) {
            admin = isAdmin(roles);
            llm = isLLM(roles);
            user = isUser(roles);
            api = isApiUser(roles);
            uac = isUaUser(roles);
        }
    } catch ({ name, message }) {
        console.log(name); 
        console.log(message);
    }
    return {
        isLLM: llm,
        isAdmin: admin,
        isUser: user,
        isApiUser: api,
        isUaUser: uac,
    };
}

const ap = (type, params) => {
    if (type === AUTH_LOGIN) {
        const { username, password } = params;
        const request = new Request(TOKEN_BASE_URL, {
            method: 'POST',
            body: `grant_type=password&username=${username}&password=${password}`,
            //body: JSON.stringify({ "grant_type":"password", "username":username, "password":password }),
            headers: new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' }),
        })
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error("Login failed. Error code: " + response.status);
                }
                return response.json();
            })
            .then(({ access_token, roles }) => {
                localStorage.setItem('token', access_token);
                localStorage.removeItem('roles');
                secureLocalStorage.setItem('roles', roles);
            });
    }
    if (type === AUTH_LOGOUT) {
        localStorage.removeItem('token');
        secureLocalStorage.removeItem('roles');
      return Promise.resolve();
    }
    if (type === AUTH_ERROR) {
        const status  = params.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            secureLocalStorage.removeItem('roles');
            return Promise.reject();
        }
        return Promise.resolve();
    }
    if (type === AUTH_CHECK) {
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
    }
    if (type === AUTH_GET_PERMISSIONS) {
        const roles = secureLocalStorage.getItem('roles');
        let p = Permissions(roles);
        return p ? Promise.resolve(p) : Promise.reject();
    }    
    return Promise.resolve();
}
export default ap;