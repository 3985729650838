import './PrivacyPolicy.css';
import Link from 'react-router-dom/Link';
const TermsandConditions = () => {
    return (
        <div className="MsoNormal">
        <h1 style={{color:'#303030'}} ><span lang="EN-US">PFX Select Terms &amp; Conditions:</span><span
        lang="EN-US"></span></h1>

        <p >2023-05-09</p>
        <h3>License Agreement for Danfoss Heat Exchanger selection software (HEX SSW)</h3>

        <p lang="EN-US">CAREFULLY READ THIS SOFTWARE LICENSE
        AGREEMENT (&quot;AGREEMENT&quot; or “Agreement”). BY DOWNLOADING THE SOFTWARE
        AND/OR CLICKING THE APPLICABLE BUTTON TO COMPLETE THE INSTALLATION PROCESS, YOU
        AGREE TO BE BOUND BY THE TERMS OF THIS AGREEMENT. IF YOU DO NOT WISH TO BECOME
        A PARTY TO THIS AGREEMENT AND BE BOUND BY ITS TERMS AND CONDITIONS, DO NOT
        INSTALL OR USE THE SOFTWARE.</p>

        
        <p lang="EN-US">If You are accepting these terms on behalf
        of an entity, You agree that You have authority to
        bind the entity to these terms.</p>

        

        <p lang="EN-US">The terms of this Agreement apply to the
        software provided with this Agreement, all updates or upgrades to the software
        that may be provided later by Danfoss, unless such update or upgrade comes with
        separate software license terms (collectively &quot;Software&quot; or
        &quot;SOFTWARE&quot;).</p>
        <h2>1. License</h2>

        <p lang="EN-US">This Agreement is entered into between
        Danfoss A/S (“Danfoss”) and you or the entity on behalf of which you accept
        this Agreement (collectively “You” or “User”).</p>

        

        <p lang="EN-US">Upon acceptance of this Agreement Danfoss
        grants You a limited, non-exclusive, non-transferable, royalty-free, worldwide
        license (“License”) to install and use the Software for User’s use on any
        device that User owns or controls. The License is granted subject to User’s
        acceptance of, and compliance with, the following:</p>

        <p lang="EN-US">a) &emsp; The terms of this Agreement</p>

        <p lang="EN-US">b) &emsp; <Link to='/privacypolicy' target='_blank' rel='noopener noreferrer' onClick={() => this.setState({hasReadPrivacyPolicy: true })}>Danfoss Privacy Policy</Link></p>
        <p lang="EN-US">Danfoss reserves the right, at its sole discretion,
        to terminate the License at any time and/or to change, suspend, remove or disable User’s access to the Software at any time
        without notice.</p>

        

        <p lang="EN-US">The License does not transfer any ownership
        rights to You. Danfoss shall remain the exclusive owner of any
        and all intellectual property rights concerning the Software, including
        without limitation patents, inventions, copyrights, database rights, design
        rights (whether registered or not), trademarks, trade names, logos, trade
        secrets and know-how.</p>

        <h2>2. Open Source Software</h2>

        

        <p lang="EN-US">The Software may include certain third party open source and free software components
        (&quot;Open Source Components&quot;), each of which has its own copyright and
        its own license conditions (&quot;Open Source License&quot;).</p>

        

        <p lang="EN-US">To the extent any Open
        Source License grants the User rights to use, copy or modify the Open
        Source Component that are broader than the rights granted in this Agreement,
        then such rights shall take precedence over the rights and restrictions granted
        in this Agreement solely for such Open Source Components.</p>

        

        <p lang="EN-US">The Open Source
        Components are provided &quot;as is&quot; by the third party licensors who
        disclaim all liabilities, damages, (even if they have been advised of the
        possibility of such damages), warranties, indemnities and other obligations of
        any kind, express or implied, with regard to the Open Source Components. The Open Source Components are excluded from any indemnity
        provided by Danfoss in this License.</p>

        <h2>3. Support</h2>

        <p lang="EN-US">Danfoss may at its sole discretion make
        available to the User support services in respect to the Software.</p>

        

        <p >For support please email:
        <a href={"mailto:SWHelp@danfoss.com"}>SWHelp@danfoss.com</a></p>

        <h2>4. Personal data</h2>

        <p lang="EN-US">By your registration and use of HEXSelector you
        provide among other things your personal data to Danfoss A/S, Nordborgvej 81, DK-6430 Nordborg,
        Denmark, being the parent company of the Danfoss Group. Danfoss A/S is the Data
        Controller for the processing of personal data in respect HEXSelector.In this regard, Danfoss may process your
        personal data of the following data categories:</p>
        <ul>
            <li>Contact data e.g. name, email address, phone number</li>
            <li>Digital footprints e.g.cookies, IP address</li>
            <li>Employment information e.g. name of employer, position, title</li>
            <li>Other IDs e.g. username, password</li>
        </ul>

        <p lang="EN-US">to administer your account and provide you
        with products, services, and information that you request from us, to provide
        general customer service and support; or to gain customer insights and
        knowledge of how our various services, including applications and products are
        used as well as evaluation and improvement hereof. Danfoss may contact you by
        way of e-mail, text message, telephone, or mail with respect to above purpose.</p>

        

        <p lang="EN-US">The processing of the personal data is
        lawful because it is necessary for the purpose of the legitimate interest
        pursued by Danfoss which is not overridden by your interests or fundamental
        rights of privacy and freedom and/or occasionally.</p>

        

        <p lang="EN-US">For further information about how Danfoss
        processes your personal data and your data privacy
        rights please refer to the Danfoss Privacy Policy.</p>

        

        <p lang="EN-US">You may at any time withdraw this consent
        by sending an email to <a href={"mailto:SWHelp@danfoss.com"}>SWHelp@danfoss.com</a> </p>
       

        <h2>5. Restrictions</h2>

        <p lang="EN-US">Except as expressly permitted by Danfoss You may not </p>
        <ul>
            <li>use the Software for any purpose except in compliance with this Agreement;</li>
            <li>copy or reproduced the Software in any form;</li>
            <li>sublicense, rent, lease, sell, distribute the Software or exploit the Software commercially;</li>
            <li>modify, reverse-engineer, decompile, de-assemble the Software, or in any other way attempt to investigate the source code of the Software;</li>
            <li>Alter or remove the copyright notices from the Software</li>
        </ul>

        <h2>6. Limitation of Liability</h2>

        <p lang="EN-US">To the extent not prohibited by law, in no
        event shall Danfoss be liable for any direct, special, indirect
        or consequential damages, whatsoever, including, without limitation, damage to
        property, damages for loss of savings or profits, or loss of data arising out
        of any use of the Software, or arising out of any inability to use the
        Software.</p>

        <h2>7. Disclaimer of warranty</h2>

        <p lang="EN-US">This License and Software is provided on an
        “as is” and “as available” basis for the agreed purposes only and any use
        hereof is at User’s sole risk. </p>

        

        <p lang="EN-US">Danfoss disclaims all warranties and
        conditions regarding the Software, whether express, implied, or statutory,
        including, but not limited to, conditions of merchantability, satisfactory
        quality, fitness for a particular purpose, accuracy
        and non-infringement of third parties’ rights. Danfoss does not warrant that
        the Software will meet your requirements or that the operation hereof will be
        uninterrupted or error-free.</p>

        <h2>8. Miscellaneous</h2>

        <p lang="EN-US">Entire agreement - This Agreement
        supersedes all prior agreements, understandings or
        arrangements (both oral and written) relating to the subject matter of this
        Agreement.</p>

        

        <p lang="EN-US">Automatic termination - The License shall
        automatically terminate upon User’s breach of any of the terms of this
        Agreement. Danfoss may at any time terminate the License at its sole discretion
        without prior notice.</p>

        

        <p lang="EN-US">Amendments - No variation,supplement or replacement of this Agreement or any of its
        terms, including this provision, shall be effective unless in writing and
        signed by or on behalf of each party.</p>

        

        <p lang="EN-US">Severability - If one or several provisions
        of this Agreement become invalid or unenforceable, the remaining provisions of
        the Agreement are not affected thereby.</p>

        

        <p lang="EN-US">Choice of law and venue - This Agreement
        and the License is subject to the laws of Denmark without regard to conflict of
        laws provisions. Any dispute arising out of or in
        connection with this Agreement shall be submitted to the exclusive jurisdiction
        of the courts in Denmark.</p>

        </div>
            );
        };
export default TermsandConditions;