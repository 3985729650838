import React, { Component } from "react";
import { API_BASE_URL } from '../config';
import {
	Grid,
	TextField,
	Paper,
	Button,
	CircularProgress,
} from '@material-ui/core';

const centerPaperOnPageStyle = {
	width: '50%',
	margin: '0 auto',
	padding: '10px'
};
const inputWidth = {
	width: '90%'
}


class PreResetPassword extends Component {

    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = {
            isBusy: false,
            hasSubmitted: false,
            message: '',
            email: '',
            currentStep: 0,
        };
    }

    submit = () => {
        let values = this.state;
        if (!values.email) {
            this.setState({
                ...this.state,
                message: 'Please type in your email.'
            });
            return;
        }
        this.setState({
            ...this.state,
            isBusy: true,
        });
        fetch(`${API_BASE_URL}/account/armPreResetPassword`, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(values),
        })
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        ...this.state,
                        isBusy: false,
                        hasSubmitted: true,
                    });
                }
                else {
                    this.setState({
                        ...this.state,
                        isBusy: false,
                        hasSubmitted: false,
                        message: response,
                    });
                }
            });
    };

    render() {
        return (
            this.state.hasSubmitted ?
                <div>
                    <Paper style={centerPaperOnPageStyle}>
                        <p>
                            Password reset email has sent to you
                        </p>
                        <p>
                            Please check out your mailbox to finish password reset
                        </p>
                    </Paper>
                </div>
                :
            <div>
                <Paper style={centerPaperOnPageStyle}>
                    <Grid
                        spacing={2}
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                    >
                        <Grid item xs={12}>
                            <p>
                                Enter your email and press Next. An email with a link will be sent to your email address. Click the link to complete your password reset process
                            </p>
                        </Grid>
                        <Grid item xs={12}>
                        <TextField
                                style={inputWidth}
                                label='Email'
                                type='email'
                                value={this.state.email}
                                onChange={(evt) => this.setState({email: evt.target.value })}
                            />
                        </Grid>
                        <Grid>
                        <Button variant='contained' color='primary' onClick={() => this.submit()} disabled={this.state.isBusy}>
                            {this.state.isBusy && (
                                <CircularProgress size={20}/>
                            )}
                            Next
                        </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        );
    }
}

export default PreResetPassword;