import React, { Component, Fragment } from "react";
import Link from 'react-router-dom/Link';
import { API_BASE_URL } from '../config';
import {
	Grid,
	TextField,
	Paper,
	Stepper,
	Step,
	StepLabel,
	Button,
	CircularProgress,
	Checkbox,
	FormControlLabel,
	// FormControl,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {MIN_PASSWORD_LENGTH} from '../config';
import { getUserCountry } from "./UserCountryLookup";



const centerPaperOnPageStyle = {
	width: '50%',
	margin: '0 auto',
	padding: '10px'
};
const inputWidth = {
	width: '90%'
}
const errorStyle = {
	color: 'white',
	backgroundColor: '#f00',
	width: '100%'
};

const segmentDescriptions = {
	"Armstrong" : "Liquid/liquid and steam/liquid duties. Product types are gasketed and single-phase brazed plate heat exchangers.",
};

/**
 * Implements the 3 required steps to sign up for a HEXSelector account
 */
class RegistrationForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isBusy: false,
			errorMessage: '',
			hasReadPrivacyPolicy: true,
			hasReadTermsAndConditions: true,
			hasAcceptedPP: false,
			hasAcceptedTOS: false,
			//hasAccepted: false,
			codeSent: false,
			hasVerifiedEmail: false,
			requestClicked: false,
			currentStep: 0,
			verificationInfo: {
				email: '',
				confirmationCode: '',
			},
			userInfo: {
				email: '',
				password: '',
				verifyPassword: '',
				firstName: '',
				surName: '',
				company: '',
				companyType: 'Armstrong',
				title: '',
				city: '',
				country: getUserCountry(),
				phoneNumber: '',
				useHexSelector: true,
				hexSelector: {
					interestDomain: 'Armstrong',
					contactPerson: '',
				}
			},
			segmentHoverText: "",
			availableCountries:
			[
				"Afghanistan", "Albania", "Algeria", "American Samoa", "Andorra", "Angola", "Argentina",
				"Armenia", "Australia", "Austria", "Azerbaijan",
				"Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Bolivia",
				"Bosnia and Herzegovina", "Brazil", "Bulgaria",
				"Cambodia", "Canada", "Central African Republic", "Chile", "China", "Colombia",
				"Costa Rica", "Croatia", "Cuba", "Cyprus", "Czech Republic",
				"Denmark", "Dominican Republic", "Dubai U.A.E",
				"Ecuador", "Egypt", "El Salvador", "Estonia",
				"Faroe Islands", "Finland", "France",
				"Gambia", "Georgia", "Germany", "Ghana", "Greece", "Guatemala",
				"Honduras", "Hong Kong", "Hungary",
				"Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland", "Israel", "Italy",
				"Jamaica", "Japan", "Jordan",
				"Kazakhstan", "Kenya", "Kosovo", "Kuwait", "Kyrgyzstan",
				"Latvia", "Lebanon", "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg",
				"Macau", "Macedonia", "Malaysia", "Malta", "Mauritius", "Mexico", "Moldova", "Monaco",
				"Mongolia", "Montenegro", "Morocco", "Mozambique",
				"Namibia", "Nepal", "Netherlands", "New Zealand", "Nicaragua", "Nigeria", "North Korea", "Norway",
				"Oman",
				"Pakistan", "Panama", "Paraguay", "Peru", "Philippines", "Poland", "Portugal", "Puerto Rico",
				"Qatar", "Romania", "Russia", "San Marino", "Saudi Arabia", "Serbia", "Singapore", "Slovakia",
				"Slovenia", "South Africa", "South Korea",
				"Spain", "Sri Lanka", "Sweden", "Switzerland", "Syria", "Taiwan", "Tajikistan", "Tanzania",
				"Thailand", "Tunisia", "Turkey", "Turkmenistan", "Uganda",
				"Ukraine", "United Arab Emirates", "United Kingdom", "Uruguay", "USA", "Uzbekistan",
				"Venezuela", "Viet Nam", "Yemen", "Zambia", "Zimbabwe"
			],
			availableSegments: 
			[
				"Armstrong",
			]
		}
	}

	handleForwardClick() {
		if (!this.canMoveForward()) {
			return;
		}
		this.setState({ currentStep: this.state.currentStep + 1 });
	}

	validateUserAccountInformation() {
		let msg = [];
		let ui = this.state.userInfo;
		if (!ui.email) {
			msg.push('Email cannot be empty');
		}
		if (this.state.verificationInfo.email !== ui.email) {
			msg.push(`Email ${ui.userInfo.email} does not match the verified email ${ui.verificationInfo.email}`);
		}
		if (ui.password !== ui.verifyPassword) {
			msg.push('Password must match the password verify input');
		}

		return msg;
	}



	canMoveForward() {
		let currentStep = this.state.currentStep;
		let canMove = false;
		switch (currentStep) {
			case 0: return this.state.hasAcceptedPP && this.state.hasAcceptedTOS && this.state.hasReadPrivacyPolicy && this.state.hasReadTermsAndConditions;
			case 1: return this.state.codeSent && this.state.hasVerifiedEmail;
			case 2: return this.validateAccountInfo() && this.state.requestClicked;
			// case 3: return this.state.requestClicked;
			default: return canMove;
		}
	}

	onClickSendCode() {
		this.setState({
			isBusy: true
		});
		fetch(`${API_BASE_URL}/account/armPreRegister?email=${this.state.verificationInfo.email}`, {
			method: 'GET',
			headers: new Headers({
				'Content-Type': 'application/json'
			})
		})
		.then(r => {
			if (r.status === 200) {
				this.setState({ isBusy: false, codeSent: true });
			} else if (r.status === 400) {
				this.setState({ isBusy: false, errorMessage: `Account already exists`});
			} else {
				this.setState({ isBusy: false, errorMessage: `Failed to send confirmation code` });
			}
		})
		.catch(err => {
			this.setState({ isBusy: false, errorMessage: err.message });
		});
		console.log(`${API_BASE_URL}/account/armPreRegister?email=${this.state.verificationInfo.email}`);
	}

	onClickVerifyCode() {
		this.setState({ isBusy: true });
		fetch(`${API_BASE_URL}/account/VerifyEmail?email=${this.state.verificationInfo.email}&verificationCode=${this.state.verificationInfo.confirmationCode}`, {
			method: 'GET',
			headers: new Headers({
				'Content-Type': 'application/json'
			})
		})
		.then(r => {
			if (r.status === 200) {
				let ui = this.state.userInfo;
				ui.email = this.state.verificationInfo.email;
				this.setState({
					hasVerifiedEmail: true,
					userInfo: ui,
					currentStep: this.state.currentStep + 1,
					busy: false,
				});
			}
			else {
				this.setState({ isBusy: false, errorMessage: `${r.status}: Could not verify code. It may be too old. Please retry the previous step` });
			}
		}).catch(err => {
			this.setState({ isBusy: false, errorMessage: err.message });
		})
	}

	handleEmailChange(event)  {
		let vInfo = this.state.verificationInfo;
		vInfo.email = event.target.value;
		this.setState({ verificationInfo: vInfo });
	};
	
	handleCodeChange(event) {
		let vInfo = this.state.verificationInfo;
		vInfo.confirmationCode = event.target.value;
		this.setState({ verificationInfo: vInfo });
	}

	handelSubmit(event) {
		// console.log(event);
		event.preventDefault();
	}

	handleUserInfoChange(event) {
		let t = event.target;
		let n = event.target.name;
		let ui = this.state.userInfo;
		ui[n] = t.value;
		this.setState({ userInfo : ui });
		// console.log(this.state);
	}

	handleUserInfoHexSelectorChange(event) {
		let t = event.target;
		let n = event.target.name;
		let ui = this.state.userInfo;
		console.log(t.value);
		ui.hexSelector[n] = t.value;
		this.setState({ userInfo : ui });
		// console.log(this.state);
	}

	handlesUserInfoUseHexSelector(event) {
		let t = event.target;
		let checked = t.checked;
		let ui = this.state.userInfo;
		ui.useHexSelector = checked;
		this.setState({ userInfo: ui });
	}

	showSegmentTooltip(event) {
		let t = event.target;
		let n = event.target.name;
		console.log(t);
		console.log(n);
	}

	createTextField(label, name, type='text') {
		return (
			<TextField
				style={inputWidth}
				label={label}
				type={type}
				name={name}
				value={this.state.userInfo[name]}
				onChange={(evt) => this.handleUserInfoChange(evt)}
			/>
		);
	}

	async onSubmitClicked() {
		let accountInfo = this.gatherAccountInfo();
		// console.log(accountInfo);
		this.setState({ busy: true });

		let response = await fetch(`${API_BASE_URL}/account/armRegister`, {
			method: 'POST',
			headers: new Headers({
				'Content-Type': 'application/json'
			}),
			body: JSON.stringify(accountInfo),
		});
		if (response.status !== 200) {
			let body = await response.json();
			this.setState({ isBusy: false, errorMessage: body.message });
		} else {
			this.setState({ busy: false, currentStep: this.state.currentStep + 1, requestClicked: true });
		}
	}

	onSubmitClick() {
		let accountInfo = this.gatherAccountInfo();
		// console.log(accountInfo);
		this.setState({busy: true});
		fetch(`${API_BASE_URL}/account/armRegister`, {
			method: 'POST',
			headers: new Headers({
				'Content-Type': 'application/json'
			}),
			body: JSON.stringify(accountInfo),
		})
		.then(r => {
			console.log(r);
			if (r.status !== 200) {
				let msg = r.body();
				console.log(msg);
				this.setState({ isBusy: false, errorMessage: msg });
			} else {
				this.setState({ busy: false, currentStep: this.state.currentStep + 1, requestClicked: true });
			}
		}).catch(err => {
			console.log(err);
			// this.setState({ isBusy: false, errorMessage: 'err' });
		});
		// console.log(this.state.userInfo);
	}

	validateAccountInfo() {
		let acc = this.gatherAccountInfo();
		let valid = true;
		if (!acc.Email) { 
			valid = false;
		}
		if (!acc.Password || !acc.ConfirmPassword || acc.Password !== acc.ConfirmPassword || acc.Password.length < 8) {
			valid = false;
		} 
		if (!acc.Surname) {
			valid = false;
		}
		if (!acc.GivenName) {
			valid = false;
		}
		if (!acc.Company) {
			valid = false;
		}
		if (!acc.HEXSelectorInterestedDomain) {
			valid = true;
		}
		console.log(`validateAccountInfo() = ${valid}`);
		return valid;
	}

	gatherAccountInfo() {
		let ui = this.state.userInfo;
		let accountInfo = {
			Email: ui.email,
			Password: ui.password,
			ConfirmPassword: ui.verifyPassword,
			EmailConfirmationCode: this.state.verificationInfo.confirmationCode,
			Surname: ui.surName,
			GivenName: ui.firstName,
			Company: ui.company,
			CompanyType: ui.companyType,
			Title: ui.title,
			Country: ui.country,
			City: ui.city,
			PhoneNumber: ui.phoneNumber,
			HEXSelectorInterestedDomain: ui.hexSelector.interestDomain,
			HEXSelectorContactPerson: ui.hexSelector.contactPerson,
		}
		return accountInfo;
	}

	getStepContent(step) {
		switch(step){
			case 0: return (
			<Paper style={ centerPaperOnPageStyle }>
				<Grid
				spacing={2}
				container
				direction="row"
				justifyContent="flex-start"
				alignItems="flex-start"
  				>
				<Grid item xs={12}>
						As a user of Armstrong PFX Select you will need to read and accept the Armstrong Privacy Policy as well as the Armstrong PFX Select Terms of Service
				</Grid>
				<Grid item xs={12}>
						Click both links and read them through. If you agree check both the boxes at the bottom to advance to the next step
				</Grid>
				<Grid item xs={12}>
				<ul>
					<li>
						<Link to='/privacypolicy' target='_blank' rel='noopener noreferrer' onClick={() => this.setState({hasReadPrivacyPolicy: true })}>Armstrong Privacy Policy</Link>
					</li>
				   	<li>
					   <Link to='/termsconditions' target='_blank' rel='noopener noreferrer' onClick={() => this.setState({hasReadTermsAndConditions: true })}>Armstrong PFX Select Terms and Conditions</Link>
						
					</li>
				</ul>
				</Grid>
					<Grid item xs={12}>
						<FormControlLabel
							label='I Accept the Armstrong Privacy Policy'
							control={<Checkbox 
								checked={this.state.hasAcceptedPP}
								onChange={() => this.setState({ hasAcceptedPP: !this.state.hasAcceptedPP })}
							/>}
						>
						</FormControlLabel>
					</Grid>
						<Grid item xs={12}>
							<FormControlLabel
								label='I Accept The Armstrong PFX Select Terms of Service'
								control={<Checkbox
									checked={this.state.hasAcceptedTOS}
									onChange={() => this.setState({ hasAcceptedTOS: !this.state.hasAcceptedTOS })}
								/>}
							>
							</FormControlLabel>
						</Grid>
				</Grid>
			</Paper>
			);
			case 1: return (
			<Paper style={ centerPaperOnPageStyle }>
				<Grid
				spacing={2}
				container
				direction="row"
				justifyContent="flex-start"
				alignItems="flex-end"
  				>
					<Grid item xs={12}>
						<h3>Verify Email</h3>
					</Grid>
					<Grid item xs={12}>
						Before we can continue with the registration process we need to verify your email.
					</Grid>
					<Grid item xs={12}>
						Enter you email below and press the Send Code button
					</Grid>
					<Grid item xs={12}>
						A verification code (6 digits) should be sent to your email. Enter the code in the Code field below and press the Verify button to advance to the next step
					</Grid>
					<Grid item xs={10}>
						<TextField value={this.state.verificationInfo.email} onChange={(evt) => this.handleEmailChange(evt)} style={inputWidth} label='Email' type='email' required></TextField>
					</Grid>
					<Grid item xs={2}>
						<Button
							disabled={this.state.isBusy}
							variant="contained"
							color="primary"
							onClick={() => this.onClickSendCode()}>
								{this.state.codeSent ? 'Resend' : 'Send'} {this.state.isBusy ? <CircularProgress size={20} /> : null}
						</Button>
					</Grid>
					{this.state.codeSent ?
					<Fragment>
						<Grid item xs={12}>
							<span>A verification code has been sent to your email. Enter the code into the textbox below and press the Verify</span>
						</Grid>
						<Grid item xs={10}>
							<TextField
							value={this.state.verificationInfo.confirmationCode}
							onChange={(evt) => this.handleCodeChange(evt)}
							type='number'
							required
							style={inputWidth} 
							label='Confirmation Code'>
							</TextField>
						</Grid>
						<Grid item cs={2}>
							<Button
								disabled={this.state.isBusy}
								variant="contained"
								color="primary"
								onClick={() => this.onClickVerifyCode()}>
									Verify {this.state.isBusy ? <CircularProgress size={20} /> : null}
							</Button>
						</Grid>
					</Fragment>
					: null }
				</Grid>
			</Paper>
			);
			case 2: return (
				<Paper style={ centerPaperOnPageStyle }>
					<Grid
						spacing={2}
						container
						direction="row"
						justifyContent="flex-start"
						alignItems="flex-end"
					>
						<Grid item xs={12}>
							<h3>User Account Information</h3><span>({this.state.userInfo.email})</span>
						</Grid>
						<Grid item xs={6}>
							<TextField
							style={inputWidth}
							label='Password'
							type='password'
							name='password'
							error={this.state.userInfo.password.length < MIN_PASSWORD_LENGTH}
							helperText={`Password must be at least ${MIN_PASSWORD_LENGTH} characters long`}
							value={this.state.userInfo.password}
							onChange={(evt) => this.handleUserInfoChange(evt)}
							>
							</TextField>
						</Grid>
						<Grid item xs={6}>
							<TextField
							style={inputWidth}
							label='Verify Password'
							type='password'
							name='verifyPassword'
							error={this.state.userInfo.password !== this.state.userInfo.verifyPassword}
							onChange={(evt) => this.handleUserInfoChange(evt)}
							value={this.state.userInfo.verifyPassword}
							helperText='Passwords must match'
							>
							</TextField>
						</Grid>
						<Grid item xs={6}>
							<TextField
							style={inputWidth}
							label='First Name'
							type='text'
							name='firstName'
							error={!this.state.userInfo.firstName}
							onChange={(evt) => this.handleUserInfoChange(evt)}
							value={this.state.userInfo.firstName}
							helperText='First name is required'
							>
							</TextField>
							{/* {this.createTextField('First Name', 'firstName')} */}
						</Grid>
						<Grid item xs={6}>
						<TextField
							style={inputWidth}
							label='Surname'
							type='text'
							name='surName'
							error={!this.state.userInfo.surName}
							onChange={(evt) => this.handleUserInfoChange(evt)}
							value={this.state.userInfo.surName}
							helperText='Surname is required'
							>
							</TextField>
							{/* {this.createTextField('Surname', 'surName')} */}
						</Grid>
						<Grid item xs={6}>
							<TextField
							style={inputWidth}
							label='Company'
							type='text'
							name='company'
							error={!this.state.userInfo.company}
							onChange={(evt) => this.handleUserInfoChange(evt)}
							value={this.state.userInfo.company}
							helperText='Company is required'
							>
							</TextField>
							{/* {this.createTextField('Company', 'company')} */}
						</Grid>
						<Grid item xs={6}>
							<TextField
							style={inputWidth}
							label='City'
							type='text'
							name='city'
							error={!this.state.userInfo.city}
							onChange={(evt) => this.handleUserInfoChange(evt)}
							value={this.state.userInfo.city}
							helperText='City is required'
							>
							</TextField>
							{/* {this.createTextField('City', 'city')} */}
						</Grid>
						<Grid item xs={6}>
							<FormControl fullWidth>
								<InputLabel>Company Type</InputLabel>
								<Select
									style={inputWidth}
									label='Company Type'
									name='companyType'
									value={this.state.userInfo.companyType}
									onChange={(evt) => this.handleUserInfoChange(evt)}
								>
										<MenuItem value="Armstrong">
									Armstrong
										</MenuItem>
										<MenuItem value="OEM">
									OEM
										</MenuItem>
										<MenuItem value="Wholesaler">
									Wholesaler
										</MenuItem>
										<MenuItem value="Contractor">
									Contractor
										</MenuItem>
										<MenuItem value="Consulting Engineer">
									Consulting Engineer
										</MenuItem>
										<MenuItem value="Student">
									Student
										</MenuItem>
										<MenuItem value="Utility">
									Utility
										</MenuItem>
										<MenuItem value="Other">
									Other
										</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={6}>
							<FormControl fullWidth>
								<InputLabel>Country</InputLabel>
								<Select
									style={inputWidth}
									label='Country'
									name='country'
									value={this.state.userInfo.country}
									onChange={(evt) => this.handleUserInfoChange(evt)}
								>
									{this.state.availableCountries.map(country => {
										return <MenuItem key={country} value={country}>{country}</MenuItem>
									})}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={6}>
							{this.createTextField('Title', 'title')}
						</Grid>
						<Grid item xs={6}>
							{this.createTextField('Phone', 'phoneNumber')}
						</Grid>
						<Grid item xs={6}>
							<FormControl
								// style={inputWidth}
								fullWidth
								error={!this.state.userInfo.hexSelector.interestDomain}
							>
								{false && <TextField
									name='interestDomain'
									value='Armstrong'
								>
								</TextField>}
							</FormControl>
							{/* <p>{segmentDescriptions[this.state.userInfo.hexSelector.interestDomain]}</p> */}
						</Grid>
						<Grid item xs={6}>
							<TextField 
							style={inputWidth}
								label='Contact Person'
								type='text'
								name='contactPerson'
								onChange={(evt) => this.handleUserInfoHexSelectorChange(evt)}
								value={this.state.userInfo.hexSelector.contactPerson}
							/>
						</Grid>
						<Grid item xs={12}>
							<Grid item xs={11}></Grid>
							<Grid item xs={1}>
							<Button
								variant="contained"
								color="primary"
								disabled={(!this.validateAccountInfo() || this.state.busy)}
								onClick={async () => await this.onSubmitClicked()}
							>
								Request
							</Button>
							</Grid>
						</Grid>
					</Grid>
				</Paper> 
			);
			case 3: return (
			<Paper style={ centerPaperOnPageStyle }>
				{this.state.requestClicked ? <div>Registration complete</div> : <div>Request not sent! Please use the arrow back and click the Request button</div>}
			</Paper>);
			default: return <div></div>;
		};
	}

	render() {
		const steps = ['Terms and privacy', 'Verify Email', 'User Account Information', 'Finish'];
		return (
			<div>
				<Grid
					spacing={2}
					container
					direction="row"
				>
					<Grid item container justifyContent="center" alignItems="flex-end" xs={12}>
						<Grid xs={6} item>
						<Stepper activeStep={this.state.currentStep}>
							{steps.map(label => {
								return (
									<Step key={label}>
										<StepLabel>{label}</StepLabel>
									</Step>
								);
							})}
						</Stepper>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<div style={{ paddingLeft: 32, paddingRight: 32 }}>
							{this.getStepContent(this.state.currentStep)}
						</div>
					</Grid>
					<Grid item xs={6} container justifyContent="center" alignItems="flex-end">
						<Grid item xs={1}></Grid>
						<Grid item xs={1}>
							<Button
							style={ { width: '100%'}}
							variant='contained'
							color='primary'
							disabled={(this.state.currentStep === 0)}
							onClick={() => this.setState({ currentStep: this.state.currentStep - 1})}>
								<ArrowBackIcon />
							</Button>
						</Grid>
					</Grid>
					<Grid item xs={6} container justifyContent="center" alignItems="flex-end">
						<Grid item xs={1}>
							<Button
							style={ { width: '100%'}}
							variant='contained'
							color='primary'
							disabled={!this.canMoveForward()}
							onClick={() => this.handleForwardClick()}>
								<ArrowForwardIcon />
							</Button>
						</Grid>
						<Grid item xs={1}></Grid>
					</Grid>
					{this.state.errorMessage === '' ? null : 
						<Grid item container xs={12} justifyContent="center">
							<Paper style={centerPaperOnPageStyle}>
								<h3 style={errorStyle} severity="error" >{this.state.errorMessage}</h3>
							</Paper>
						</Grid>
					}
					{process.env.NODE_ENV === 'development' ? 
					<Grid item xs={12}>
						<h4>Debug Steppers</h4>
						<Button 
						disabled={this.state.currentStep === 0}
						onClick={()=>this.setState({ currentStep: Math.max(this.state.currentStep - 1, 0)})}>
							Back
						</Button>
						<Button 
						disabled={this.currentStep > 0 && this.currentStep < 4}
						onClick={()=>this.setState({ currentStep: Math.min(this.state.currentStep + 1, 4)})}>
							Forward
						</Button>
					</Grid>
					:null }
				</Grid>
			</div>
		);
	}
}

export default RegistrationForm;